<template>
  <div class="am-error-404">
    <div class="am-error-404__inner">
      <amui-compact-toolbar
        v-if="appMode === 'widget' && !isAdMode"
        class="am-error-404__compact-toolbar"
      >
        <amui-compact-link
          v-if="favoriteVehiclesCount"
          :label="$t('vdp.toolbar.favorites')"
          icon="favorite"
          :badge="favoriteVehiclesCount"
          @click.native="onClickFavorites"
        />
        <amui-compact-link
          :label="$t('vdp.toolbar.search')"
          icon="tune-simple"
          @click.native="onClickSearch"
        />
      </amui-compact-toolbar>

      <amui-headline
        html-tag="h1"
        looks-like="h5"
        class="am-error-404__headline"
        >{{ $t('error-404.headline') }}</amui-headline
      >
      <div class="am-error-404__text">
        {{ $t(isAdMode ? 'error-404.textAdMode' : 'error-404.text') }}
      </div>
      <amui-button
        v-if="!isAdMode"
        icon="tune-simple"
        :label="$t('error-404.cta')"
        @click="onClickSearch"
      />
    </div>
  </div>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiButton } from '@/../ui/components/button'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AmError404',

  components: {
    AmuiHeadline,
    AmuiButton,
    AmuiCompactToolbar,
    AmuiCompactLink
  },

  metaInfo() {
    return {
      title: this.$t('error-404.meta.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('error-404.meta.description')
        },
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  },

  computed: {
    ...mapState('core', ['appMode']),
    ...mapState('favorites', {
      favoriteVehiclesCount: state => state.vehicles.length
    }),
    ...mapGetters('core', ['isAdMode'])
  },

  methods: {
    onClickFavorites() {
      this.$router.push({ name: 'favorites' })
    },
    onClickSearch() {
      this.$router.push({ name: 'app' })
    }
  }
}
</script>
